import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Header from "../components/regions/header"
import Container from "../components/block/container"
import FieldText from "../components/field/fieldText"
import ImageTextPositioned from "../components/item/imageTextPositioned"
import ContactInfo from "../components/item/contactInfo"
import "../styles/components/block--page-top-content--company.scss"
import "../styles/components/block--text.scss"
import "../styles/components/block--company-goals.scss"
import "../styles/components/block--contact-info--long.scss"

const CompanyPage = () => (
  <>
    <Header />
    <Layout>
      <Seo
        title="Company"
        description="Our mission is to help you build a company people love."
        pageClass="page--company"
      />

      <Container classes="block--page-top-content block--page-top-content--company">
        <FieldText>
          <h1>Our mission is to help you build a company people love</h1>
        </FieldText>
        <StaticImage
          as="figure"
          className="field field--image"
          src="../assets/images/company--mission.png"
          quality="90"
          placeholder="blurred"
          alt="Our mission is to help you build a company people love"
        />
      </Container>

      <Container classes="block--text">
        <FieldText>
          <h3 className="text text--red text--uppercase">Our Mission</h3>
          <p>
            Our goal is to help millions of people around the world through our
            products. While growth is important, team and values are paramount.
            We're always striving to do impactful work we're proud of in lieu of
            chasing overnight success.
          </p>
        </FieldText>
      </Container>

      <Container classes="block--company-goals">
        <ImageTextPositioned
          position="right"
          classes="image-text--agency-owners"
        >
          <StaticImage
            as="figure"
            className="field field--image"
            src="../assets/images/company--agency-owners.jpg"
            quality="90"
            placeholder="blurred"
            alt="Founded by agency owners, for agency owners"
          />
          <FieldText>
            <h3>Founded by agency owners, for agency owners</h3>
            <p>
              Our goal is to help millions of people around the world through
              our products. While growth is important, team and values are
              paramount.
            </p>
            <ul className="list list--success">
              <li className="text text--italic">
                We understand your pain point
              </li>
              <li className="text text--italic">
                Clients know the status of their requests
              </li>
              <li className="text text--italic">Track billable hours</li>
              <li className="text text--italic">Eliminate inbox clutter</li>
            </ul>
          </FieldText>
        </ImageTextPositioned>

        <ImageTextPositioned position="left" classes="image-text--where-going">
          <StaticImage
            as="figure"
            className="field field--image"
            src="../assets/images/company--where-we-going.jpg"
            quality="90"
            placeholder="blurred"
            alt="Where we're going"
          />
          <FieldText>
            <h3>Where we're going</h3>
            <p>
              Our goal is to help millions of people around the world through
              our products. While growth is important, team and values are
              paramount.
            </p>
            <ul className="list list--success">
              <li>Future expansion, goals and product development</li>
              <li>Email opt in</li>
              <li>Track billable hours</li>
              <li>Eliminate inbox clutter</li>
            </ul>
          </FieldText>
        </ImageTextPositioned>
      </Container>

      <Container classes="block--contact-info block--contact-info--long">
        <ContactInfo label="Partnership inquiries:" mail="partners@sideline.io">
          <StaticImage
            as="figure"
            className="field field--image"
            src="../assets/images/company--partnership-inquiries.png"
            layout="fixed"
            quality="90"
            placeholder="blurred"
            alt="Partnership inquiries"
          />
        </ContactInfo>

        <ContactInfo label="General contact:" mail="hello@sideline.io">
          <StaticImage
            as="figure"
            className="field field--image"
            src="../assets/images/company--general-contact.png"
            quality="90"
            layout="fixed"
            placeholder="blurred"
            alt="General contact"
          />
        </ContactInfo>

        <ContactInfo label="Support:" mail="support@sideline.io">
          <StaticImage
            as="figure"
            className="field field--image"
            src="../assets/images/company--support.png"
            quality="90"
            layout="fixed"
            placeholder="blurred"
            alt="Support"
          />
        </ContactInfo>
      </Container>
    </Layout>
  </>
)

export default CompanyPage
