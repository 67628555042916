import * as React from "react"
import PropTypes from "prop-types"

const ContactInfo = ({ children, label, mail }) => {
  return (
    <div className="contact">
      {children}
      <div className="field field--info">
        <div className="info-label">{label}</div>
        <div className="info-value">
          <a href={`mailto:${mail}`}>{mail}</a>
        </div>
      </div>
    </div>
  )
}

ContactInfo.propTypes = {
  label: PropTypes.string.isRequired,
  mail: PropTypes.string.isRequired,
  children: PropTypes.node,
}

export default ContactInfo
