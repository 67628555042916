import * as React from "react"
import PropTypes from "prop-types"

const ImageTextPositioned = ({ children, position, classes }) => {
  const positionClass = `image-text--${position}`
  return (
    <div
      className={`image-text ${positionClass}${classes ? ` ${classes}` : ""}`}
    >
      {children}
    </div>
  )
}

ImageTextPositioned.propTypes = {
  classes: PropTypes.string,
  position: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
}

export default ImageTextPositioned
